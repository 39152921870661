let config = {};

config.newsList = [
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041904.png",
    title: "挚达参加香港创科展InnoEX，福建省领导莅临调研",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/XhR4WWQmwNOGgJ1Z_3Ur_w",
    date: "2024-04-13",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041903.png",
    title: "家用充电桩全球销量第一品牌挚达海外首个工厂正式投产",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/mxppxXVPvXqOmK5CAhZ-NQ",
    date: "2024-04-02",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041906.png",
    title: "电动车家庭充电，撬动全球家庭能源管理新机遇",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/UjazDRVRQfNRKBdOtOUYYQ",
    date: "2024-03-19",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041902.png",
    title: "挚达科技参加泰国汽车展，推动家庭绿色能源出海",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/OJxXNyASiHXtpty1XmDuQw",
    date: "2024-03-29",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041901.png",
    title: "挚达开拓者数字家充桩获国际设计大奖",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/-X5NJeGFedJe48TNlP0fFg",
    date: "2024-03-01",
  },

  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-1009/09news06.jpg",
    title: "挚达受邀加入中汽国际战略专家委，共推中国新能源汽车“出海”",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/LdM9ZctCUsVrzpN5g_d8WQ",
    date: "2024-02-25",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-02/news-021801.jpg",
    title: "中国速度|挚达泰国充电桩生产线率先建成！",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/MnILf3Sydjq7VPT6cvnNwQ",
    date: "2024-02-04",
  },
  {
    img: "http://img.cdn.wxzhida.cn/shzd-site-imgs/news-2024-01/0201-img-02.jpg",
    title: "同济科创主题分享暨迎新交流会在挚达顺利举办",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/HcT6Gi2XJjVYbCD_MGZqPg",
    date: "2024-01-22",
  },
  {
    img: "http://img.cdn.wxzhida.cn/shzd-site-imgs/news-2024-01/0201-img-03.jpg",
    title: "挚达与支付宝签署战略合作协议",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/oxMGGQlxFr1dONnqWj9tVA",
    date: "2023-12-15",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-11/news-01.jpg",
    title: "进博会期间，泰国副总理会见挚达董事长，共促中泰新能源产业发展",
    subtitle: "聚势前行，挚达天下。",
    url: "https://mp.weixin.qq.com/s/ima2VozPEjFym8gEiLPDZg",
    date: "2023-11-09",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-11/news-02.png",
    title: "硬核家充金刚来袭，开启科技千面守护",
    subtitle: "科技美感·智能交互·绿色共享",
    url: "https://mp.weixin.qq.com/s/JmBqbjACZ29LmhRfQX1M_Q",
    date: "2023-11-08",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-1009/09news05.jpg",
    title: "挚达自动充电机器人投运中石油智能超级充换电综合示范站",
    subtitle: "为绿色出行·美好生活赋能",
    url: "https://mp.weixin.qq.com/s/rnFiOqU2MF_OBi8e-0UHtQ",
    date: "2023-09-23",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-1009/09news04.jpg",
    title: "协鑫与挚达携手在杨浦落地“电动上海”第一站",
    subtitle: "把绿色能源带进生活！",
    url: "https://mp.weixin.qq.com/s/P4c3KeB-i6MSB5G_T2ZZlw",
    date: "2023-09-22",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-1009/09news03.jpg",
    title: "率先海外落地充电桩制造基地，挚达满电出征全球市场！",
    subtitle: "长风破浪会有时，直挂云帆济沧海。",
    url: "https://mp.weixin.qq.com/s/todnmkiwGfJgtar4smOAsA",
    date: "2023-09-21",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-1009/09news02.jpg",
    title: "第八届海峡两岸青少年创客大赛圆满落幕，挚达获贡献奖",
    subtitle: "挑战新科技，携手创未来！",
    url: "https://mp.weixin.qq.com/s/nHWfHqIISsyBHPJTO76VPA",
    date: "2023-08-30",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-1009/09news01a.jpg",
    title: "展会预告 | 2023法国汽配展EQUIP AUTO见！",
    subtitle: "诚邀观展，欢迎莅临",
    url: "https://mp.weixin.qq.com/s/z2yMqw--5QHwEvyOZjqTqQ",
    date: "2023-08-24",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/shzd-new-04.jpg",
    title: "2023中国汽车论坛主题论坛“为中国车企海‘加电’”圆满召开",
    subtitle:
      "携手增强全产业链竞争力，助力新能源汽车高质量出海。挚达，诚“挚”邀请，通“达”全球。",
    url: "https://mp.weixin.qq.com/s/pgV6mEax5azFApfg-Ohn7w",
    date: "2023-07-11",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/shzd-new-03.jpg",
    title: "为汽车消费充“电”，为乡村振兴添“绿”",
    subtitle:
      "2023年新能源汽车下乡活动正式启动，挚达愿与各方共同努力，持续全力支持与服务新能源汽车下乡。",
    url: "https://mp.weixin.qq.com/s/d2iDzLCIYJKroquUtvl9JA",
    date: "2023-06-17",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/shzd-new-02.jpg",
    title: "挚·爱318，为中国国民公路“充电”",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/37d3rk-YbxNFn4SeRf3-Zg",
    date: "2023-06-06",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/shzd-new-01.jpg",
    title: "步履不停，挚达新零售持续前行",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/yftA53_V5Q7MKtNQ3ydlPQ",
    date: "2023-05-24",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-09.jpg",
    title: "私人充电桩市场“风口”来了",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/CJ8BM47VLVV_dZUbUWmWcg",
    date: "2023-05-15",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-07.jpg",
    title: "完美收官！大世界基尼斯之最创纪录",
    subtitle: "“新能源汽车累计参与次数最多的后备箱集市”",
    url: "https://mp.weixin.qq.com/s/4xhFtevylk1GPb5tfJ2dOA",
    date: "2023-05-15",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-07.png",
    title: "百辆后备箱集市，一起挑战基尼斯，挚达邀你一同见证",
    subtitle: "论后备箱的正确打开方式",
    url: "https://mp.weixin.qq.com/s/K_RmreBBhig8QMacrxwV6A",
    date: "2023-04-28",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-06.jpg",
    title: "展会预告 | 挚达科技股份邀您共聚第133届广交会",
    subtitle: "诚邀广大合作伙伴一起向着全球市场满电出发",
    url: "https://mp.weixin.qq.com/s/US3Onv_9CfBWOmLUGHAkJA",
    date: "2023-04-10",
    imgClas: "set-height-img",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-05.jpg",
    title: "福建三明市委书记黄如欣一行赴挚达三明公司调研指导",
    subtitle: "客服·城市充电运营·直播·集采",
    url: "https://mp.weixin.qq.com/s/b3kI6G37Ipo-HyBXiBBgOQ",
    date: "2023-04-10",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-04.jpg",
    title: "挚达相伴新能源自主品牌亮相第44届曼谷国际车展",
    subtitle: "挚达正在中国新能源汽车出海浪潮中贡献重要力量。",
    url: "https://mp.weixin.qq.com/s/4H-xmZFkK7c2LnUxiGc5jQ",
    date: "2023-04-03",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-03.jpg",
    title: "挚达2023全国合作伙伴大会（挚友日）圆满落幕",
    subtitle: "聚势前行，挚达天下！",
    url: "https://mp.weixin.qq.com/s/RnpJHBbYpwezrZ7PjP8t5g",
    date: "2023-03-18",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-02.jpg",
    title: "大片来袭 | 挚达开拓者数字家充桩，引领未来智能交互体验",
    subtitle: "新时代崛起 开拓者已来",
    url: "https://mp.weixin.qq.com/s/ljeyKZKlK1NxF6M-X3WYYA",
    date: "2023-03-07",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/0523-img-01.jpg",
    title: "私董会 | 转型之路，治理先行",
    subtitle:
      "在一个高速增长和充分竞争的新兴赛道里，家庭智能充电和绿色能源数字科技领军企业如何持续保持行业领先？",
    url: "https://mp.weixin.qq.com/s/gBSDWkSEMsfY3w4gij8KqQ",
    date: "2023-03-01",
  },

  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-09.png",
    title: "2022盘点 | 挚达的九个“新”",
    subtitle: "2023，满电出发！",
    url: "https://mp.weixin.qq.com/s/V_-M7ip8Cw_pJsO0EQzr1Q",
    date: "2022-12-30",
  },

  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-08.png",
    title: "ZD life Store | 全球首家新能源数字生活馆启幕",
    subtitle: "充电·懂车·爱生活，期待与你相遇！",
    url: "https://mp.weixin.qq.com/s/P5oUWih0gfgaQaEDWsSFYw",
    date: "2022-10-09",
  },

  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-07.jpg",
    title: "挚达连续喜获四大奖项，为祖国庆生！",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/ZOB4MZ0REs7yutqgFTUtQQ",
    date: "2022-10-01",
  },

  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-06.jpg",
    title: "上海市科委副主任谢文澜一行赴挚达科技调研指导工作",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/zXEW_-4pLwQ1WocOFy5KFA",
    date: "2022-09-07",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-05.jpg",
    title: "金砖财经专访挚达科技李欣瑞：充电桩市场的想象力有多大？",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/IbUC90uyVCGoKhrZ5kI_Pg",
    date: "2022-08-17",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-04.jpg",
    title: "六年换车，几年换桩？| 是什么保障了充电桩的质量？",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/wxSlR6DwFHLUdHExQrIzrQ",
    date: "2022-08-12",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-03.png",
    title: "挚达品牌形象焕新，全球品牌战略升级",
    subtitle: "专注于家庭的，全球化的，新能源数字化生活服务生态",
    url: "https://mp.weixin.qq.com/s/nb9xBVJd-g8oQm3TymwcvA",
    date: "2022-07-11",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-02.jpg",
    title: "挚·爱318 | 自由之路，充电随心！",
    subtitle: "充电·懂车·爱生活",
    url: "https://mp.weixin.qq.com/s/LYdfRSq1i2TLRaTeMFzXyg",
    date: "2022-05-24",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-web-site/news/news-img-01.jpg",
    title: "打响“新工厂+直播基地”双响炮，挚达520惊喜到达！",
    subtitle:
      "祝贺挚达第二生产基地一期工程成功试产！祝贺挚达·新能源生活馆顺利启用！",
    url: "https://mp.weixin.qq.com/s/QQj4LDTMKQamApAe4yZjaQ",
    date: "2022-05-20",
  },
];

config.firstNewsList = [
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041904.png",
    title: "挚达参加香港创科展InnoEX，福建省领导莅临调研",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/XhR4WWQmwNOGgJ1Z_3Ur_w",
    date: "2024-04-13",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041903.png",
    title: "家用充电桩全球销量第一品牌挚达海外首个工厂正式投产",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/mxppxXVPvXqOmK5CAhZ-NQ",
    date: "2024-04-02",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041906.png",
    title: "电动车家庭充电，撬动全球家庭能源管理新机遇",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/UjazDRVRQfNRKBdOtOUYYQ",
    date: "2024-03-19",
  },
];

config.secondNewsList = [
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041902.png",
    title: "挚达科技参加泰国汽车展，推动家庭绿色能源出海",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/OJxXNyASiHXtpty1XmDuQw",
    date: "2024-03-29",
  },
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-04/shzd-img-news-041901.png",
    title: "挚达开拓者数字家充桩获国际设计大奖",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/-X5NJeGFedJe48TNlP0fFg",
    date: "2024-03-01",
  },

  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-1009/09news06.jpg",
    title: "挚达受邀加入中汽国际战略专家委，共推中国新能源汽车“出海”",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/LdM9ZctCUsVrzpN5g_d8WQ",
    date: "2024-02-25",
  },
];

config.thirdNewsList = [
  {
    img: "https://img.cdn.wxzhida.cn/shzd-site-imgs/news-month-02/news-021801.jpg",
    title: "中国速度|挚达泰国充电桩生产线率先建成！",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/MnILf3Sydjq7VPT6cvnNwQ",
    date: "2024-02-04",
  },
  {
    img: "http://img.cdn.wxzhida.cn/shzd-site-imgs/news-2024-01/0201-img-02.jpg",
    title: "同济科创主题分享暨迎新交流会在挚达顺利举办",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/HcT6Gi2XJjVYbCD_MGZqPg",
    date: "2024-01-22",
  },
  {
    img: "http://img.cdn.wxzhida.cn/shzd-site-imgs/news-2024-01/0201-img-03.jpg",
    title: "挚达与支付宝签署战略合作协议",
    subtitle: "",
    url: "https://mp.weixin.qq.com/s/oxMGGQlxFr1dONnqWj9tVA",
    date: "2023-12-15",
  },
];

config.jxDataList = [
  {
    dataIndex: 69,
    fileName: "tuv认证.jpg",
    imgUrl: "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/tuv认证.jpg",
  },
  {
    dataIndex: 68,
    fileName: "ce认证.jpg",
    imgUrl: "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/ce认证.jpg",
  },
  {
    dataIndex: 67,
    fileName: "2023德国莱茵认证TUV.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/2023德国莱茵认证TUV.jpg",
  },
  {
    dataIndex: 66,
    fileName: "2023复日大学管理学院后EMBA私董会(第八期)感谢铭牌.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/2023复日大学管理学院后EMBA私董会(第八期)感谢铭牌.jpg",
  },
  {
    dataIndex: "65",
    fileName: "最佳车友.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/65.最佳车友.png",
  },
  {
    dataIndex: "64",
    fileName: "最佳创业先锋.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/64.最佳创业先锋.png",
  },
  {
    dataIndex: "63",
    fileName: "杨浦工匠.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/63.杨浦工匠.png",
  },
  {
    dataIndex: "62",
    fileName: "同济大学港澳台学生就业实习基地.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/62.同济大学港澳台学生就业实习基地.jpg",
  },
  {
    dataIndex: "61",
    fileName: "FOUNDERS CLASS商业计划书大赛 冠军.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/61.FOUNDERS CLASS商业计划书大赛 冠军.png",
  },
  {
    dataIndex: "60",
    fileName: "杨浦区认定企业技术中心.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/60.杨浦区认定企业技术中心.jpg",
  },
  {
    dataIndex: "59",
    fileName: "2024奇瑞瑞享生活 生态合作伙伴.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/59.2024奇瑞瑞享生活 生态合作伙伴.png",
  },
  {
    dataIndex: "58",
    fileName: "2023科技创新奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/58.2023科技创新奖.png",
  },
  {
    dataIndex: "57",
    fileName: "2023复旦科创企业家营走进学员企业.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/57.2023复旦科创企业家营走进学员企业.jpg",
  },
  {
    dataIndex: "56",
    fileName: "2023年创智天地二十荣耀.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/56.2023年创智天地二十荣耀.png",
  },
  {
    dataIndex: "55",
    fileName: "2023全省推动长三角地区更高质量一体化发展优秀个人.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/55.2023全省推动长三角地区更高质量一体化发展优秀个人.jpg",
  },
  {
    dataIndex: "54",
    fileName: "2023第八届海峡两岸青少年创客大赛贡献奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/54.2023第八届海峡两岸青少年创客大赛贡献奖.jpg",
  },
  {
    dataIndex: "53",
    fileName: "2023上海市商业秘密保护示范点.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/53.2023上海市商业秘密保护示范点.jpg",
  },
  {
    dataIndex: "52",
    fileName: "2023京东车品品质联盟战略签约合作伙伴—安全先锋奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/52.2023京东车品品质联盟战略签约合作伙伴—安全先锋奖.png",
  },
  {
    dataIndex: "51",
    fileName: "2023私域营销引领者.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/51.2023私域营销引领者.png",
  },
  {
    dataIndex: "50",
    fileName: "2023新能源汽车累计参与次数最多的后备箱集市活动.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/50.2023新能源汽车累计参与次数最多的后备箱集市活动.jpg",
  },
  {
    dataIndex: "48",
    fileName: "2023三明市沙县区优秀创业创新项目大赛一等奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/48.2023三明市沙县区优秀创业创新项目大赛一等奖.png",
  },
  {
    dataIndex: "47",
    fileName: "2023上海市企业技术中心.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/47.2023上海市企业技术中心.jpg",
  },
  {
    dataIndex: "46",
    fileName: "2022邓白氏注册认证企业.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/46.2022邓白氏注册认证企业.png",
  },
  {
    dataIndex: "45",
    fileName: "2021最具影响力奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/45.2021最具影响力奖.jpg",
  },
  {
    dataIndex: "44",
    fileName: "2022中国制造网认证供应商.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/44.2022中国制造网认证供应商.jpg",
  },
  {
    dataIndex: "43",
    fileName: "2022上海市专利工作试点企业.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/43.2022上海市专利工作试点企业.jpg",
  },
  {
    dataIndex: "42",
    fileName: "2022企业境外投资证书.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/42.2022企业境外投资证书.jpg",
  },
  {
    dataIndex: "41",
    fileName: "2022上海市杨浦区商业秘密保护示范点.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/41.2022上海市杨浦区商业秘密保护示范点.jpg",
  },
  {
    dataIndex: "40",
    fileName: "2021年度上海现代服务业联合会特殊贡献奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/40.2021年度上海现代服务业联合会特殊贡献奖.jpg",
  },
  {
    dataIndex: "39",
    fileName: "2022年上海市高价值专利运营大赛20强.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/39.2022年上海市高价值专利运营大赛20强.jpg",
  },
  {
    dataIndex: "39",
    fileName: "2022年上海市高价值专利运营大赛百强.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/39.2022年上海市高价值专利运营大赛百强.jpg",
  },
  {
    dataIndex: "38",
    fileName: "2022上海高新技术企业.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/38.2022上海高新技术企业.jpg",
  },
  {
    dataIndex: "37",
    fileName: "2022年度达人热推品牌.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/37.2022年度达人热推品牌.png",
  },
  {
    dataIndex: "36",
    fileName: "2022第十一届金砖价值榜年度科创先锋奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/36.2022第十一届金砖价值榜年度科创先锋奖2.jpg",
  },
  {
    dataIndex: "35",
    fileName: "2022安永复旦最具潜力企业.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/35.2022安永复旦最具潜力企业2.jpg",
  },
  {
    dataIndex: "34",
    fileName: "2022中国充换电行业十大最具投资价值品牌.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/34.2022中国充换电行业十大最具投资价值品牌.png",
  },
  {
    dataIndex: "33",
    fileName: "2020年度杨浦区企业技术中心评份良好.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/33.2020年度杨浦区企业技术中心评份良好.jpg",
  },
  {
    dataIndex: "32",
    fileName: "2021常务委员会.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/32.2021常务委员会.jpg",
  },
  {
    dataIndex: "31",
    fileName: "2021特殊贡献奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/31.2021特殊贡献奖.png",
  },
  {
    dataIndex: "30",
    fileName: "2021上海市“专精特新”中小企业.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/30.2021上海市“专精特新”中小企业.jpg",
  },
  {
    dataIndex: "29",
    fileName: "2020上海设计100+.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/29.2020上海设计100+.jpg",
  },
  {
    dataIndex: "28",
    fileName: "2021工信部专精特新“小巨人”企业.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/28.2021工信部专精特新“小巨人”企业.jpg",
  },
  {
    dataIndex: "28",
    fileName: "2021工信部专精特新“小巨人”企业2.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/28.2021工信部专精特新“小巨人”企业2.jpg",
  },
  {
    dataIndex: "27",
    fileName: "2020年度星耀天地 最快成长企业奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/27.2020年度星耀天地 最快成长企业奖.png",
  },
  {
    dataIndex: "26",
    fileName: "2020香格里拉合作伙伴奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/26.2020香格里拉合作伙伴奖.jpg",
  },
  {
    dataIndex: "25",
    fileName: "2020创新协作攻关奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/25.2020创新协作攻关奖.jpg",
  },
  {
    dataIndex: "24",
    fileName: "2020华东江苏大数据交易中心会员单位.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/24.2020华东江苏大数据交易中心会员单位.jpg",
  },
  {
    dataIndex: "23",
    fileName: "年度数字科技明星产品奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/23.年度数字科技明星产品奖.png",
  },
  {
    dataIndex: "22",
    fileName: "合同信用等级AAA级证书（2018-2019）-上海挚达.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/22.合同信用等级AAA级证书（2018-2019）-上海挚达.jpg",
  },
  {
    dataIndex: "21",
    fileName: "守合同重信用企业2018-2019（2年）.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/21.守合同重信用企业2018-2019（2年）.jpg",
  },
  {
    dataIndex: "20",
    fileName: "黄志明同志入选2019年上海领军人才.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/20.黄志明同志入选2019年上海领军人才.jpg",
  },
  {
    dataIndex: "19",
    fileName: "2020上海市工业设计中心.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/19.2020上海市工业设计中心.jpg",
  },
  {
    dataIndex: "18",
    fileName: "2020优秀活动奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/18.2020优秀活动奖.png",
  },
  {
    dataIndex: "17",
    fileName: "2019同济校友产业创新联盟执行主席单位.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/17.2019同济校友产业创新联盟执行主席单位.jpg",
  },
  {
    dataIndex: "16",
    fileName: "2019质量创新奖提名奖-杨浦区人民政府.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/16.2019质量创新奖提名奖-杨浦区人民政府.png",
  },
  {
    dataIndex: "15",
    fileName: "2018年度最快成长企业奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/15.2018年度最快成长企业奖.png",
  },
  {
    dataIndex: "14",
    fileName: "2019杨浦区双创小巨人企业.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/14.2019杨浦区双创小巨人企业.jpg",
  },
  {
    dataIndex: "13",
    fileName: "2019首届杨浦区质量创新奖提名奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/13.2019首届杨浦区质量创新奖提名奖.jpg",
  },
  {
    dataIndex: "12",
    fileName: "2019年杨浦区经济发展创新奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/12.2019年杨浦区经济发展创新奖.png",
  },
  {
    dataIndex: "11",
    fileName: "2017年度优秀战略合作奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/11.2017年度优秀战略合作奖.png",
  },
  {
    dataIndex: "10",
    fileName: "2017年度上海市职工合理化建议项目创新奖.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/10.2017年度上海市职工合理化建议项目创新奖.jpg",
  },
  {
    dataIndex: "9",
    fileName: "2017年度最快成长企业.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/9.2017年度最快成长企业.png",
  },
  {
    dataIndex: "8",
    fileName: "2018年度优秀学员企业.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/8.2018年度优秀学员企业.png",
  },
  {
    dataIndex: "7",
    fileName: "2018年上海现代服务业优秀企业家.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/7.2018年上海现代服务业优秀企业家.png",
  },
  {
    dataIndex: "6",
    fileName: "2017年度杰出充电奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/6.2017年度杰出充电奖.png",
  },
  {
    dataIndex: "5",
    fileName: "2017TopDigital创新奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/5.2017TopDigital创新奖.png",
  },
  {
    dataIndex: "4",
    fileName: "2016年度优秀充电服务商奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/4.2016年度优秀充电服务商奖.png",
  },
  {
    dataIndex: "3",
    fileName: "2016年度最来电合作伙伴.jpg",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/3.2016年度最来电合作伙伴.jpg",
  },
  {
    dataIndex: "2",
    fileName: "2015年度卫蓝优秀充电服务商奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/2.2015年度卫蓝优秀充电服务商奖.png",
  },
  {
    dataIndex: "1",
    fileName: "2015年度充换电商奖.png",
    imgUrl:
      "https://img.cdn.wxzhida.cn/shzd-site-imgs/jiangxiang/1.2015年度充换电商奖.png",
  },
];

export default config;
